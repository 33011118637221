import React from 'react'
import { Redirect } from '@reach/router'
import { graphql } from 'gatsby'
import Layout from '../components/layout/Layout'
import Text from '../components/text/Text'
import Headline from '../components/headline/Headline'
import Container from '../components/container/Container'
import JobTitle from '../components/jobs/JobTitle'
import FormContainer from '../components/form/FormContainer'
import RandomImages from '../components/randomImages/RandomImages'
import { sortProject } from '../misc/sortProject'


export default ({ data: { job, career, form, projects, minigame }, pageContext }) => {

  if (!job) return <Redirect to={`/${pageContext.locale}/404`} noThrow/>

  const { title, text, project, workload, job_email, show_mini_game } = job.data
  projects.edges.sort(sortProject)


  return (
    <Layout
      locale={pageContext.locale}
      lang={pageContext.lang}
      alternatePath={pageContext.alternatePath}
      alternateLocale={pageContext.alternateLocale}>

      <Headline headline={career.data.headline}/>

      <Container>
        <JobTitle
          title={title.text}
          project={project ? project.document[0].data.title.text : ''}
          workload={workload}
          location={job.data.location}/>
        <Text text={text}/>
      </Container>

      <FormContainer
        title={title.text}
        formdata={form.data}
        projects={projects.edges}
        jobEmail={job_email}
        isMiniGameEnabled={show_mini_game === 'Yes'}
        miniGameData={minigame.data}
        projectRelatedToTheJob={project ? project.document[0].slugs[0] : ''}/>

      <RandomImages images={project ? project.document[0].data.random_images : []}/>
    </Layout>
  )
}

export const query = graphql`
query jobQuery($id: String!, $lang: String!) {
  job: prismicJob(id: {eq: $id}) {
    data {
      title {
        text
      }
      text {
        html
      }
      workload
      location
      job_email
      show_mini_game
      project {
        document {
          slugs
          data {
            title {
              text
            }
            random_images {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
  career: prismicCareer(lang: {eq: $lang}) {
    data {
      headline {
        text
      }
    }
  }
  projects: allPrismicProject(filter: {lang: {eq: $lang}}) {
    edges {
      node {
        slugs
        data {
          title {
            text
          }
          order
          job_email
        }
      }
    }
  }
  form: prismicForm(lang: {eq: $lang}) {
    data {
      label_name
      placeholder_name
      label_email
      placeholder_email
      label_phone
      placeholder_phone
      label_project
      placeholder_project
      label_message
      placeholder_message
      label_fileupload
      placeholder_fileupload
      submit_button_text
      success {
        html
      }
      error {
        html
      }
    }
  }
  minigame: prismicMinigame(lang: {eq: $lang}) {
    data {
      introduction_text {
        html
      }
      introduction_button
      rules_text {
        html
      }
      rules_button
      result_text {
        html
      }
      result_button
    }
  }
}
`