import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import styles from './JobTitle.module.scss'

class JobTitle extends PureComponent {

    render() {
        const { title, project, workload, location } = this.props

        return (
            <div className={styles.Main}>
                    <div className={styles.Eyebrow}>
                        <span className={styles.Item}>{project}</span>
                        <span className={styles.Item}>{location}</span>
                        <span className={styles.Item}>{workload}</span>
                    </div>
              <h4 className={styles.Title} dangerouslySetInnerHTML={{ __html: title }}/>
            </div>
        )
    }
}

JobTitle.propTypes = {
    title: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    workload: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
}

export default JobTitle
